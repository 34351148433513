// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-certifications-tsx": () => import("./../src/pages/Certifications.tsx" /* webpackChunkName: "component---src-pages-certifications-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../src/pages/Contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-education-tsx": () => import("./../src/pages/Education.tsx" /* webpackChunkName: "component---src-pages-education-tsx" */),
  "component---src-pages-experience-tsx": () => import("./../src/pages/Experience.tsx" /* webpackChunkName: "component---src-pages-experience-tsx" */),
  "component---src-pages-header-tsx": () => import("./../src/pages/Header.tsx" /* webpackChunkName: "component---src-pages-header-tsx" */),
  "component---src-pages-honors-tsx": () => import("./../src/pages/Honors.tsx" /* webpackChunkName: "component---src-pages-honors-tsx" */),
  "component---src-pages-index-tsx": () => import("./../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-media-links-tsx": () => import("./../src/pages/MediaLinks.tsx" /* webpackChunkName: "component---src-pages-media-links-tsx" */),
  "component---src-pages-skills-tsx": () => import("./../src/pages/Skills.tsx" /* webpackChunkName: "component---src-pages-skills-tsx" */)
}

